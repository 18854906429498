*{
    margin: 00px;
    padding: 00px;
    box-sizing: content-box;
}
.css-j8df3z-MuiPaper-root-MuiCard-root {
    background-color: #ededf7 !important;
}
.container{
    /* height: 100vh; */
    margin-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 13px;
    flex-direction: row;
    flex-flow: wrap;
}
.font{
    height: 375px;
    width: 250px;
    position: relative;
    /* font-family: 'Nexa', sans-serif; */
    border-radius: 10px;
    background-color: white;
}
.top{
    height: 30%;
    width: 100%;
    background-color: white;
    position: relative;
    z-index: 5;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.card-title{
    position: relative;
    top: -5px;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 15px;
}
.bottom{
    height: 70%;
    width: 100%;
    background-color: white;
    position: absolute;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}
.id-logo{
    height: 72px;
    width: 70px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    top: 1px;
    left: 25px;
}
.info-text{
    font-size: 11px;
}
.bck-img{
    margin-left: 102px;
    border-radius: 100%;
    margin-top: 10px;
}
.top .id-img{    
    height: 180px;
    width: 155px;
    background-color: white;
    position: absolute;
    border: 2px solid black;
    top: 86px;
    border-radius: 12px;
    left: 67px;
}

.top .id-back-logo{    
    height: 60px;
    width: 60px;
    background-color: white;
    position: absolute;
    top: 65px;
    left: 115px;
}
.ahm{
    font-size: 15px;
    color: black;
    font-weight: 900;
    position: absolute;
    font-family: math;
    top: 17px;
    right: 60px;
    line-height: 17px;
}

.ahm-back{
    font-size: 11.1px;
    color: black;
    font-weight: 900;
    position: absolute;
    font-family: math;
    top: 157px;
    right: 40px;
    line-height: 20px;
    margin-right: -11px;
    margin-top: -40px;
}

.ahm-back-maan{
    font-size: 11.1px;
    color: black;
    font-weight: 900;
    position: absolute;
    font-family: math;
    top: 157px;
    right: 40px;
    line-height: 35px;
    margin-right: 30px;
    margin-top: -40px;
}

.ahm-back-2{
    font-size: 8px;
    color: black;
    font-weight: 900;
    position: absolute;
    font-family: math;
    top: 177px;
    right: 40px;
    line-height: 30px;
    margin-right: -7px;
    margin-top: -43px;
}

.ahm-back-laj-2{
    font-size: 9px;
    color: black;
    font-weight: 900;
    position: absolute;
    font-family: math;
    top: 177px;
    right: 40px;
    line-height: 25px;
    margin-right: 40px;
    margin-top: -43px;
}

.ahm-back-khu-2{
    font-size: 9px;
    color: black;
    font-weight: 900;
    position: absolute;
    font-family: math;
    top: 177px;
    right: 40px;
    line-height: 50px;
    margin-right: 25px;
    margin-top: -65px;
}

.ahm-back-maan-2{
    font-size: 9px;
    color: black;
    font-weight: 900;
    position: absolute;
    font-family: math;
    top: 177px;
    right: 40px;
    line-height: 65px;
    margin-right: 25px;
    margin-top: -65px;
}

.ahm-back-maan-3{
    font-size: 9px;
    color: black;
    font-weight: 900;
    position: absolute;
    font-family: math;
    top: 177px;
    right: 40px;
    line-height: 65px;
    margin-right: 40px;
    margin-top: -43px;
}

.ahm-back-laj-3{
    font-size: 9px;
    color: black;
    font-weight: 900;
    position: absolute;
    font-family: math;
    top: 177px;
    right: 40px;
    line-height: 65px;
    margin-right: 2px;
    margin-top: -43px;
}

.ahm-back-laj-4{
    font-size: 9px;
    color: black;
    font-weight: 900;
    position: absolute;
    font-family: math;
    top: 177px;
    right: 40px;
    line-height: 65px;
    margin-right: 35px;
    margin-top: -30px;
}

.ahm-back-mr-2{
    margin-right: 9px;
}

.mid-info{
    top: 287px;
}
.back-head-info{
    font-size: 10px;
    color: black;
    font-weight: bold;
    position: absolute;
    font-family: math;
    top: 27px;
    right: 56px;
    line-height: 17px;
    font-style: italic;
    text-align: center;
    margin-left: 10px;
    margin-bottom: 10px;
    
}
.back-head-info-2{
    font-size: 10px;
    color: black;
    font-weight: bold;
    position: absolute;
    font-family: math;
    top: 37px;
    right: 59px;
    line-height: 17px;
    font-style: italic;
    margin-right: 19px;
    margin-top: 4px;
}
.memNo{
    font-size: 15px !important;
}
.bottom p{
    color: black;
    position: relative;
    top: 130px;
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
}

.bottom-back p{
    color: black;
    position: relative;
    top: 90px;
    text-align: center;
    text-transform: inherit;
    line-height: 22px;
}

.mid-info p{
    color: black;
    position: relative;
    top: 70px;
    text-align: center;
    text-transform: inherit;
}
.sig{
    font-size: 16px;
    font-weight: bold;
    margin-top: 3px;
}

.signature{
    font-size: 25px;
    font-style: italic;
    font-family: initial;
}
.gensec{
    font-size: 14px;
    font-weight: bold;
}

.bottom .desi{
    font-family: Francois One;
    font-size: 12px;
    color: grey;
    font-weight: normal;
}
.id-size{
    height: 457px;
    width: 290px;
}
.bottom .no{
    font-size: 14px;
    font-family: math;
    font-weight: bold;

}
.barcode >img{
    height: 95px;
    width: 95px;
    text-align: center;
    margin: 5px;
    margin-top: 41px;
}
.barcode{
     text-align: center;
     position: relative;
     top: 7px;
}
.back{
    height: 446px;
    width: 277.39px;
    position: relative;
    border-radius: 10px;
    background: linear-gradient(45deg, #06eb35, transparent);
    background-image: url(./idcardBack.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
}
.back::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5; /* Adjust the opacity value to control the darkness */
    z-index: -1;
  }
.qr >img{
    height: 80px;
    width: 100%;
    margin: 20px;
    background-color: white;
}
.Details{
    color: rgb(40 92 155);
    text-align: center;
    padding: 10px;
    font-size: 25px;
}
.padding{
    padding-right: 20px;
    padding-left: 20px;
}
.details-info{
    color: green;
    text-align: left;
    line-height: 20px;
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
    line-height: 22px;
}
.logo{
    height: 40px;
    width: 150px;
    padding: 30px;
}
.logo >img{
    height: 40px;
    width: 208px;
    position: absolute;
    top: 358px;
}
.sig-img{
    margin-top: 7px;
    height: 30px;
    margin-left: 77px;
}
.underline{
    margin-top: 89px;
    height: 2px;
    width: 180px;
    margin-left: 54px;
}
@media screen and (max-width:400px) {
    .container{
        height: 130vh;
        margin-top: -68px;
    }
    .container .front{
        margin-top: 50px;
    }
}
.headrers-design{
    width: 100%;
    height: 65%;
    /* background: rgb(173, 69, 0); */
    position: relative;
}
.head-curve{
    width: 100%;
    position: absolute;
    clip-path: circle(40.7% at 100% 0);
    height: 100%;
    /* background: rgba(0,173,40,1); */
}
.first{
    background-color: green;
}
.sec{
    background-color: rgb(2, 179, 2); clip-path: circle(34.8% at 100% 0);
}
.third{
    background-color: rgb(50, 250, 50);     clip-path: circle(27.8% at 100% 0);
}
.end{
    position: absolute;
    background-color: green;
    top: 15.8pc;
    width: 17pc;
    height: 60px;
    display: flex;
    margin-left: 9px;
}

.end-back{
    position: absolute;
    background-color: white;
    top: 15.8pc;
    width: 17pc;
    height: 60px;
    display: flex;
    margin-left: 9px;
    margin-bottom: 5px;
}
.qr-des{
    width: 80px !important;
    height: 72px!important;
    right: 6.0pc;
    top: -6px;
    position: absolute;
    border: 5px solid white;
}

.qr-des-back{
    width: 80px !important;
    height: 72px!important;
    right: 6.0pc;
    top: -20px;
    position: absolute;
    border: 5px solid white;
}