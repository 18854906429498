
.css-1rujksk-MuiContainer-root {
    display: none !important;
}
.css-14fglbg{
    display: none !important;
}
.css-k6nd4d-MuiSnackbar-root {
    top: -30pc;
    right: 24px;
}
.css-t34gks {
    top: -30pc;
    right: 24px;
}
#logo-img{
    width: 20%;
    border-radius: 50%;
}