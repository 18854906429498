thead{
    display: contents !important;
    color: #ff4800;
}
.my-select {
    width: 12pc;
    height: 2pc;
    border-radius: 10px;
    border: 2px solid;
}
.css-1ilul2d-MuiTableCell-root {
    font-size: 0.8rem !important;
}
.css-li77g6 {
    width: 100% !important;
}
.css-u9ivjp {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 0.8rem !important;
}
.css-1b4ewkk-MuiTableCell-root {
    font-size: 0.8rem !important;
}
.css-17ljlw6 {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 0.8rem !important;
}
.css-k6nd4d-MuiSnackbar-root {
    top: -30pc;
    right: 24px;
}
.css-t34gks {
    top: -30pc;
    right: 24px;
}
@media screen and (max-width:400px) {
    .css-1mbunh-MuiPaper-root {
        margin-top: -191px !important;
    }
}
