
.css-e0k7pc-MuiCircularProgress-root {
    position: absolute;
    top: 300px;
    left: 700px;
}
.css-1m8lztj {
    position: absolute;
    top: 300px;
    left: 700px;
}