body{
    margin-top:20px;
    color: #1a202c;
    text-align: left;
    background-color: #e2e8f0;    
}
.main-body {
    padding: 15px;
}
.card {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06);
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0,0,0,.125);
    border-radius: .25rem;
}

.css-k6nd4d-MuiSnackbar-root {
    top: -30pc;
    right: 24px;
}
.css-t34gks {
    top: -30pc;
    right: 24px;
}
.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1rem;
}

@media screen and (max-width: 595px) {
    .gutters-sm {
        display: flex !important;
        flex-direction: column;
        }
  }
.gutters-sm {
    margin-right: -8px;
    margin-left: -8px;
}
.conte{
    display: grid;
    grid-template-columns: 1fr 2fr;
}
.row{
    margin-top: 20px;
}
.req{    
    color: #e2e8f0 !important;
    background-color: #27e81a !important;
    
}
.btn:hover{
    padding: 12px;
}
.gutters-sm>.col, .gutters-sm>[class*=col-] {
    padding-right: 8px;
    padding-left: 8px;
}
.mb-3, .my-3 {
    margin-bottom: 1rem!important;
}

.bg-gray-300 {
    background-color: #e2e8f0;
}
.h-100 {
    height: 100%!important;
}
.shadow-none {
    box-shadow: none!important;
}
#requestReason{
    width: 29pc;
    height: 31px;
    border-top: none;
    border-right: none;
    border-left: none;
}